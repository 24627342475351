import React from 'react'
import './Missing.css'
import {Link} from 'react-router-dom'
import {BsArrowRight} from 'react-icons/bs'
function Missing() {
    return (
        <>
        <section className="missingSection h-fscreen center column">
           
           
            <div className="none">
                <div className="aboutBrush"></div>
                <div id="menuBrush"></div>
            </div>
            <div className="missingDiv center column">
            <div className="borderBoxTop MissingBox"></div>
            <div className="borderBoxBottom MissingBox"></div>
            <div className="_404 serif">404</div>
            <div className="missingMessage">Oops! The page you were looking for doesnt exist</div>
            <div className="dots">.......</div>
            <Link to="/" className="storeBtn content serif">Back to Home &nbsp; <BsArrowRight/></Link>
            </div>


        </section>
        </>
    )
}

export default Missing

import React,{useEffect,useState} from 'react'
import axios from 'axios'
import './Menu.css'
import MenuList from '../../data/Menu.json'
import rupee from '../../images/Rupee.svg'

function MenuSpecials(props) {
    const [menu, setMenu] = useState([]);
    useEffect(() => {
        axios.get('https://cascadingninjayoutube.github.io/restaurant-menus/Menu.json').then((response) => {
          setMenu(response.data);
          console.log(menu)
        });
      }, []);
    
    return (
        <section className="menuSection center">
            {menu.length>0?
            <>
            <div className="menuCardWrapper">
            {menu.filter(item=>item.special==true).map(item=>(
                <>
                <div className="menuCard specialMenu col-md-5 col-lg-3 col-sm-12 content" key={item.id}>
                    <div className="menuImage">
                    <img src={item.image} alt="" />
                    </div>
                    <div className="menuCardDetails">
                    <div className="menuCardTop ">
                        <div className="col-xs-8">
                            <div className="recipeName">{item.recipeName}</div>
                            <div className="recipeType">
                            {item.veg?<span className="veg  recipeType">Veg</span>:<span className="nonveg  recipeType">Non-Veg</span>}
                        </div>
                        </div>
                        {/* <div className="col-xs-4 recipePrice">
                           <img src={rupee} alt="" />{item.price}
                        </div> */}
                    </div>
                    <div className="menuCardDesc">
                        {item.recipeDescription}
                    </div>
                    </div>
                </div>
                </>
            ))}
            </div>
            </>
            :
            
            <>
            <h3 className="serif">Oops! There seems to be nothing here. Check back again later or reload the page</h3>
            </>}
        </section>
    )
}

export default MenuSpecials

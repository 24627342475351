import {useState,useEffect} from 'react'
import './Nav.css'
import {Link,NavLink} from 'react-router-dom'
import WhiteLogo from '../../images/LogoWhite.png'
import BlackLogo from '../../images/LogoBlack.png'
import blackBrush from '../../images/blackBrush.png'
import whiteBrush from '../../images/whiteBrush.png'
import {HiOutlineMenuAlt4} from 'react-icons/hi'
import {MdClose} from 'react-icons/md'
function Nav() {
    const [open, setOpen] = useState(false);
    const [dark, setDark] =useState(false);
    function changeNav()
    {
        setOpen(!open)
        {open?closeNav():openNav()}
    }
    function openNav()
    {
        document.getElementById('navMenu').classList.add('open')
        document.getElementById('_1').style.transform="rotate(45deg) translate(5px ,5px)"
        document.getElementById('_2').style.transform="rotate(-45deg) translate(5px ,-5px)"
    }
    function closeNav()
    {
        document.getElementById('navMenu').classList.remove('open')
        document.getElementById('_1').style.transform="rotate(0deg) translate(0 ,0)"
        document.getElementById('_2').style.transform="rotate(0deg) translate(0 ,0)"
        
    }
    function borderNav() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
          document.getElementById("navBar").style.boxShadow = "inset 0px 0px 4px 1px rgba(255,255,255,0.14)";
        //   document.getElementById("navBar").style.borderRadius = "0";
          document.getElementById("navSection").style.marginTop = "-38px";
         
        } else {
          document.getElementById("navBar").style.boxShadow = "inset 0px 0px 4px 4px transparent";
        //   document.getElementById("navBar").style.borderRadius = "10px";
          document.getElementById("navSection").style.marginTop = "0";
       
        }
      }

      window.onscroll= borderNav;


      useEffect(() => {
      
          if(dark)
          {
           document.getElementById('lightDarkButton').style.background="var(--secondary-color)"
           document.getElementById('lightDarkButton').style.color="#fff"
           document.getElementById('lightDarkButton').firstChild.classList.add('fa-sun-o');
           document.getElementById('lightDarkButton').firstChild.classList.remove('fa-moon-o');
           document.getElementById('navLogo').src=`${BlackLogo}`;
          document.getElementById('cursor2').style.background="var(--secondary-color)";


           document.querySelector(':root').style.setProperty('--primary-color','#fff' ) 
           document.querySelector(':root').style.setProperty('--white-text','#111' ) 
           document.querySelector(':root').style.setProperty('--dark-text','#fff' )
           document.querySelector(':root').style.setProperty('--brush-filter','brightness(1200%)' )
        
           document.getElementById('menuBrush').style.filter="brightness(1200%)"
           document.getElementById('cursor2').style.background="var(--secondary-color)"; 
           
          }
          else
          {
              
           document.getElementById('lightDarkButton').style.background="var(--primary-color)"
           document.getElementById('lightDarkButton').style.color="var(--secondary-color)"
           document.getElementById('lightDarkButton').firstChild.classList.remove('fa-sun-o');
           document.getElementById('lightDarkButton').firstChild.classList.add('fa-moon-o');
           document.getElementById('navLogo').src=`${WhiteLogo}`;
           
           document.querySelector(':root').style.setProperty('--primary-color','#292929' ) 
           document.querySelector(':root').style.setProperty('--white-text','#fff' ) 
           document.querySelector(':root').style.setProperty('--dark-text','#111' ) 
           document.querySelector(':root').style.setProperty('--brush-filter','brightness(124%)' )
        document.getElementById('menuBrush').style.filter="brightness(124%)"
           
           
        }
          
      }, [dark])
    return (
        <section className="navSection" id="navSection">
                <div className="topWrapper">
                    <div className="topbarDetailWrapper">
                        <span className="type">Call:</span>
                        <span className="detail">+91 70038 85406</span>
                    </div>
                    <div className="topbarDetailWrapper">
                        <span className="type">Write:</span>
                        <span className="detail">hello@pulaoroast.com</span>
                    </div>
                </div>
                <div className="navBar" id="navBar">
                    <Link to="/" className="navBrand">
                        <img src={WhiteLogo} id="navLogo"  className="content"/>
                    </Link>
                    <div className="navRight flex">
                    <div className="navMenu" id="navMenu">
                        <NavLink className="navItem content" to="/" exact onClick={()=>closeNav()}>
                            Home
                        </NavLink>
                        <NavLink className="navItem content" to="/menu" onClick={()=>closeNav()}>
                            Menu
                        </NavLink>
                        <NavLink className="navItem content" to="/about" onClick={()=>closeNav()}>
                            About
                        </NavLink>
                        <NavLink className="navItem content" to="/gallery" onClick={()=>closeNav()}>
                            Gallery
                        </NavLink>
                        <NavLink className="navItem content" to="/contact" onClick={()=>closeNav()}>
                            Contact
                        </NavLink>
                      
                    </div>
                    <div className="hamMenu" onClick={changeNav} >                       
                    <span id="_1"></span>
                    <span id="_2"></span>
                    </div>
                    <div className="lightDarkButton content" id="lightDarkButton" onClick={()=>setDark(!dark)}>
                        
                        <i className="fa fa-moon-o" aria-hidden="true"></i>                    
                    </div>
                </div>
                </div>
              
        </section>
    )
}

export default Nav

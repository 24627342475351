import React from 'react'
import {Link} from 'react-router-dom'
import './About.css'
import DSC_0107 from '../../images/DSC_0107.jpg'
import DSC_9512 from '../../images/DSC_9512.jpg'
import spice1 from '../../images/spice1.JPG'
import spice2 from '../../images/spice2.JPG'
import {BsArrowRight} from 'react-icons/bs'
import Brush from '../Brush/Brush'


function About(props) {
    return (
        <>
        <div className="aboutBrush"></div>
       

        <section className="about h-fscreen">
            <div className="topBorder"></div>
            <div className="row">
                <div className="col-lg-6 col-sm-12 center column">
                    <div className="aboutDetail">
                        <div>
                            <span>Our story</span>
                            <h3>Few words about us</h3>
                            <div className="dots">.......</div>
                            <p>Pulao Roast is all about serving passionate, delightful dishes with absolute guest satisfaction. We love to exceed each one of our customer’s expectations. Hear safety of our employee with adequate hygiene measures is our ultimate priority.</p><p>The lost taste which faded away with generation evolution we at pulao roast have preserved the recipe. We are proud to present you the oldest authentic cultured cuisine from East Bengal in the city of joy. A home away from home which was once a part of Bengal. It’s a call for those who have heard stories of their forefather but never received a piece of them. Line can divide countries but not food. It is a tribute to all the stories unheard, who deserve a piece of their memories. Because this is what India is where all cultures Co - exist.</p>
                            {props.page==="home"?<Link to="/about" className="landingBtn  aboutBtn content"><span>More about us </span><BsArrowRight/> </Link>:
                            <>
                            <div className="aboutParaWrapper">
                            <h3>Our Mission & Goals</h3>
                            <div className="dots">.......</div>
                            <p>We are on a mission to capture hearts from all society and people by presenting fresh, appetizing, flavourful, comfortable palate which can be relished at any moment in time. A dedication to deliver quality with quantity and grant both guests and stuff with world class experience. And become one of the most beloved outlet to go to nationwide and worldwide. </p>
                            </div>
                            <div className="aboutParaWrapper">
                            <h3>Our Vision </h3>
                            <div className="dots">.......</div>
                            <p>We want to disrupt the age-old food industry by adopting the latest technological advancement with the help of machine learning and Artificial Intelligence and change the spectrum by personalizing food preferences according to every customer’s needs, again keeping exceptional condition and full transparency into consideration across all our brands, bringing in automation through robotics to reach the ultimate level of perfection during preparing the food. We practice a company culture of prioritizing our staff & employers’ safety and hygiene, also taking complaints actively and turning up with a rapid resolution by maintaining consistency.</p>
                            </div>
                            </>}
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 center" style={{position:"relative"}}>
                    <img src={DSC_9512} alt="" className="aboutImg" />
                    <img src={DSC_0107} alt="" className="aboutImg"/>
                    <div className="borderBox"></div>
                </div>
            </div>
            <div className="row spiceRow" >
                <div className="col-lg-6 col-sm-12 center column">
                    <div className="aboutDetail">
                        <div>
                            
                            <h3>"Its simple, great ingredients brings excellent taste. 
                                And the secret ingredient is always fresh ingredients."</h3>
                            <div className="dots">.......</div>
                            
                           
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 center" style={{position:"relative"}}>
                    <img src={spice1} alt="" className="aboutImg" />
                    <img src={spice2} alt="" className="aboutImg"id="spiceImg"/>
                    <div className="borderBox"></div>
                </div>
            </div>            
        </section>
        </>
    )
}

export default About

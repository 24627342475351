
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Menu from './pages/Menu';
import Gallery from './components/Gallery/Gallery';
import Nav from './components/Nav/Nav'
import Footer from './components/Footer/Footer';
import Index from './pages/Index';
import Missing from './components/Missing/Missing';
function App() {

  window.addEventListener('load',()=>
  {
    var cursor = document.querySelector(".cursor");
    var cursor2 = document.querySelector(".cursor2");
    document.addEventListener("mousemove",function(e){
      cursor.style.cssText = cursor2.style.cssText = "left: " + e.clientX + "px; top: " + e.clientY + "px;";
    });

    document.querySelectorAll('.content').forEach(content=>
      {
        content.addEventListener("mouseenter",()=>  {cursor.classList.add('hovering');cursor2.classList.add('hovering2')})
        content.addEventListener("mouseleave",()=>  {cursor.classList.remove('hovering');cursor2.classList.remove('hovering2')})
      }
      )
  })
  return (
    <div className="App">
    <Router>
      <Nav/>
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/home"  component={Home}/>
        <Route path="/about"  component={About}/>
        <Route path="/menu" exact component={Menu}/>
        <Route path="/gallery" exact component={Gallery}/>
        <Route path="/contact" exact component={Contact}/>
        <Route path="*"  component={Missing}/>

      </Switch>
      <Footer/>
        <div className="cursor"></div>
        <div className="cursor2" id="cursor2"></div>

    </Router>
    </div>
  );
}

export default App;

import React from 'react'
import Landing from '../components/Landing/Landing'
import About from '../components/About/About'
import OpeningHours from '../components/OpeningHours/OpeningHours'
import SignatureDishes from '../components/SignatureDishes/SignatureDishes'
import MenuSmall from '../components/Menu/MenuSmall'

import Contact from '../components/Contact/Contact'
import Team from '../components/Team/Team'
import Reservation from '../components/Reservation/Reservation'
import LandingSwipper from '../components/Landing/LandingSwipper'
import Preloader from '../components/preloader/Preloader'
function Home() {
    window.scrollTo(0,0)
    return (

        <>
        {/* <Preloader/> */}
         <LandingSwipper/>   
         {/* <Landing/>    */}
         <About page="home"/>
         <OpeningHours/>
         <SignatureDishes/>
         <MenuSmall/>
         <Reservation/>
         {/* <Contact/> */}
         {/* <Team/> */}

        </>
    )
}

export default Home

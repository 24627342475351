import React from 'react'
import ContactDiv from '../components/Contact/Contact'
function Contact() {
    window.scrollTo(0,0)
    return (
        <section className="contactPage">
           
            <ContactDiv/>
        </section>
    )
}

export default Contact

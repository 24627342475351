import React,{useState,useEffect} from 'react'
import'./Reservation.css'
import {BsArrowRight} from 'react-icons/bs'
import Title from '../Title/Title'
function Reservation() {
    const [openModal, setopenModal] = useState(false);

    function modalOpen()
    {
        document.getElementById('reservationModal').classList.add('openModal')
    }
    function modalClose()
    {
        document.getElementById('reservationModal').classList.remove('openModal')
    }
    function changeModal()
    {   setopenModal(!openModal);
    openModal?modalClose():modalOpen()
    }
    return (
        <>

        <div className="reservationModal" id="reservationModal">
            <div className="closeBtn content" onClick={()=>changeModal()}>&times;</div>
            <div className="reservationFormWrapper center column">
                <Title bigText="Book us now"/>
            <form action="https://script.google.com/macros/s/AKfycbykT9VtkLJZwgM_Ab5N_5bPo2oT2W3GW8bjUYCFFCd9T0TnJHtWMY4CLEVQZoXiQnTn/exec" id="reservationForm" className="gform" method="POST" onSubmit={(e)=>e.preventDefault()}>
                <div className="form-row">                
                    <div className="col-md-5 col-sm-12">
                         <input type="text" className="reservationInput" placeholder="Your name " name="Name" required/>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <input type="email" className="reservationInput" placeholder="Your email"  name="Email" required/>
                    </div>
                </div>
                <div className="form-row">                
                    <div className="col-md-5 col-sm-12">
                         <input type="tel" className="reservationInput" placeholder="Your contact"  name="Phone" required/>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <input type="text" className="reservationInput" placeholder="Your Address"  name="Address" />
                    </div>
                </div>

                <div className="form-row">                
                    <div className="col-md-5 col-sm-12">
                         <input type="text" className="reservationInput" placeholder="Event Name"  name="Event Name" required/>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <input type="text" className="reservationInput" placeholder="Event Date" onFocus={(e)=>e.target.type="date"}  name="Event Date" required/>
                    </div>
                </div>
                <div className="form-row">                
                    <div className="col-md-5 col-sm-12">
                         <input type="text" className="reservationInput" placeholder="Event Time" name="Event Time" />
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <input type="text" className="reservationInput" placeholder="Event Venue"  name="Event Venue" required/>
                    </div>
                </div>
                <div className="form-row">                
                    <div className="col-md-5 col-sm-12">
                         <input type="text" className="reservationInput" placeholder="Event Budget"  name="Budget" required/>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <input type="text" className="reservationInput" placeholder="No. of Guests"  name="Guests" required/>
                    </div>
                </div>
               
                <div className="form-row">                
                    <div className="col-md-5 col-sm-12">
                        <select className="reservationInput"  name="Preference" required>
                            <option value="#" disabled selected>Preferred Veg/Non Veg</option>
                            <option value="Veg">Veg</option>
                            <option value="NonVeg">Non Veg</option>
                        </select>
                    </div>
                    <div className="col-md-5 col-sm-12">
                    <select className="reservationInput"  name="Catering" required>
                            <option value="#" disabled selected>Catering  Required</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div className="form-row ">                
                    <div className="col-md-10">
                         <input type="submit" className="storeBtn modalBtn" value="Book Now"/>
                    </div>                    
                </div>
                <div className="form-row thankyou_message">
                       <h3 className="serif col-md-10" id="demo" style={{fontStyle:"italic"}}> </h3>
                   </div>
            </form>
            </div>
        </div>
        <section className="Reservation">
                <div className="row d-flex" >
                    <div className="col-md-12 col-lg-6 openingWrapper hourDiv center ">
                    <div className="hourWrapper center column">
                        <div className="center column">
                            <span className="serif">Book us for an event</span>
                            <h3 className="serif">Events organized</h3>
                            <span className="dots">.......</span>
                        </div>
                            <div className="EventDiv column center col-lg-6">
                                <div className="event">We provide world class services in terms of food and catering. Book us for any event , be it a small gathering or a big arrangement for your company. Find our exquisite services at an affordable rate and have a lovely experience with us.</div>
                                
                            </div>
                            
                        
                        <button className="eventBtn serif content center" onClick={()=>changeModal()}>
                        Book us now &nbsp; <BsArrowRight/>
                        </button>
                    </div>
                </div>
           

                <div className="col-md-12 col-lg-6 openingWrapper storeDiv center">
                    <div className="storeWrapper column center">
                        <div className="center column ">
                            <div className="dots">.....</div>
                            <h3 className="serif">Our Store</h3>
                            <span className="serif">Want to order food home? Visit our online store</span>
                        </div>
                        <a role="link" href="https://orders.pulaoroast.com/" target="_blank" rel="noreferrer noopener" className="storeBtn center serif content">Visit our store now &nbsp; <BsArrowRight/></a>
                      
                    </div>
            </div>
            </div>
           
        </section>
        </>
    )
}

export default Reservation

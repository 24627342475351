import React from 'react'
import './Title.css'
function Title(props) {
    return (
        <section className="title column center">
            <h5 className="serif smallText ">{props.smallText}</h5>
            <h3 className="serif bigText ">{props.bigText}</h3>
            <div className="dots">.......</div>
        </section>
    )
}

export default Title

import React from 'react'
import {Link} from 'react-router-dom'
import './Landing.css'
import {BsArrowRight} from 'react-icons/bs'
import Brush from '../Brush/Brush'
import background2 from '../../images/background2.jpg'
function Landing() {
  function openFrame()
  {
    document.getElementById('promoVideo').style.display="flex";
    document.getElementById('frameYt').src="https://www.youtube.com/embed/6SBNejEtpXI?autoplay=1 ";
  }
  function closeFrame()
  {
    document.getElementById('promoVideo').style.display="none";
    document.getElementById('frameYt').src="  ";
  }

    
  function checkDevice()
  {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
     
      document.getElementById('landingWrapper').classList.add('mobileBackground')
    }
  }




  window.onload=()=>checkDevice()
  
    return (
        <>
        <section className="preloader column center h-fscreen">
            <div class="col-sm-6 text-center"><div class="loader1">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div></div>
        </section>
        <div className="promoVideo" id="promoVideo">
        <div className="closeVideo" onClick={closeFrame}>&times;</div>
        <iframe  src="" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" autoPlay allowFullScreen id="frameYt"></iframe>
        </div>

        <section className="landingWrapper h-fscreen" id="landingWrapper">
          <div className="landingDetails">
                            <div className="dots">........</div>
              <div className="landingContent serif">
              Top Services and Premium Cuisine
              </div>
              <div className="landingHeading"> 
              {/* Welcome to Pulao Roast */}
              Launching this Diwali
              </div>
              <Link to="/about" className="landingBtn content"><span>Order Now </span><BsArrowRight/> </Link>
          </div>
          <div className="buttonContainer content" id="playBtn" onClick={()=>openFrame()}>
                    <span className="button is-play" href="#">
                    <div className="button-outer-circle has-scale-animation"></div>
                    <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                    <div className="button-icon is-play">
                      <svg height="100%" width="100%" fill="#C19D60">
                        <polygon className="triangle" points="5,0 30,15 5,30" viewBox="0 0 30 15"></polygon>
                        <path className="path" d="M5,0 L30,15 L5,30z" fill="none" stroke="#C19D60" strokeWidth="1"></path>
                      </svg>
                    </div></span></div>
        </section>
        
        </>
    )
}

export default Landing

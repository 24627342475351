import React from 'react'
import AboutDiv from '../components/About/About'
import '../components/About/About.css'
import Team from '../components/Team/Team'
function About() {
    window.scrollTo(0,0)
    return (
        <><div className="none">        
        <div id="menuBrush"></div>
        <div id="rightClick"></div>
      </div>
        <section className="AlternateWrapper AboutWrapper">
          <div className="AlternateDetails AboutDetails">
              <div className="dots">........</div>
              <div className="AlternateContent  serif">
              About Us
              </div>
              <div className="AlternateHeading"> 
              {/* Welcome to Pulao Roast */}
              Who we are ?
              </div>
              
          </div>
        </section>
           <AboutDiv/>
           <div className="aboutTeam">
           {/* <Team/> */}
           </div>
        </>
    )
}

export default About

import React from 'react'
import Brush from '../components/Brush/Brush'
import MenuDiv from '../components/Menu/Menu'
import '../components/Menu/Menu.css'
function Menu() {
    window.scrollTo(0,0)
    return (
        <section className="Menu">
            <div className="none">             
              <div id="menuBrush"></div>
              <div id="rightClick"></div>
            </div>   
            <section className="AlternateWrapper MenuWrapper">
          <div className="AlternateDetails MenutDetails">
              <div className="dots">........</div>
              <div className="AlternateContent  serif">
             Our Menu
              </div>
              <div className="AlternateHeading"> 
              {/* Welcome to Pulao Roast */}
              What we offer
              </div>
              
          </div>
        </section>            
        <div className="aboutBrush"></div>
        

            <MenuDiv count={99}/>
            <div className="dots center">.........</div>
        </section>
    )
}

export default Menu

import React,{useState,useEffect} from 'react'
import './Footer.css'
import LogoIcon from '../../images/LogoWhite.png'

function Footer() {
const [year, setYear] = useState('2021');
useEffect(() => {
    var d=new Date()
    setYear(d.getFullYear())
}, []);
    return (
        <section className="" style={{minHeight:"60vh"}}>
        <section className="Footer">
            <div className="FooterTopBar" >
                <img src={LogoIcon} alt="Pulao roast logo" className="footerLogo" />
                <div className="footerSocials">
                    <span className="serif">Follow Us on: </span>
                    <a href="https://www.facebook.com/PULAO-ROAST-102764528937491/" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    <a href="https://www.instagram.com/pulaoroast/" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                    {/* <a href="#" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-youtube-play" aria-hidden="true"></i></a> */}
                </div>
            </div>
            <div className="FooterContent row">
                <div className="col-lg-3 col-md-6">
                    <h2 className="FooterConentTitle serif">About Us</h2>
                    <div className="FooterContentPara"><p>Pulao Roast is all about serving passionate, delightful dishes with absolute guest satisfaction. We love to exceed each one of our customer’s expectations. Hear safety of our employee with adequate hygiene measures is our ultimate priority.</p></div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h2 className="FooterConentTitle serif">Contact Us</h2>
                    <div className="FooterContentContact">
                        <div className="FooterContact">
                            <span className="key">Call</span>
                            <span><a href="tel:70038 85406">+91 70038 85406</a> </span>
                        </div>
                        <div className="FooterContact">
                            <span className="key">Write</span>
                            <span><a href="mailto:hello@pulaoroast.com">hello@pulaoroast.com</a> </span>
                        </div>
                        <div className="FooterContact">
                            <span className="key">Find Us:</span>
                            <span><a href="https://goo.gl/maps/ngWWKe3kYiyp3ZcS7">TCS Gitanjali Park, Newtown</a> </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-12">
                <h2 className="FooterConentTitle serif">Subscribe</h2>
                <div className="FooterContentPara">Want to be notified when we launch a new dish or an udpate. Just sign up and we'll send you a notification by email.</div>
                <form action="https://script.google.com/macros/s/AKfycbyZmbl_KFCxUorl0PBHt2X8rnu-laKMIeKD2aQSDp-8n6m3AmDwyirSp51ghQxb_vvoew/exec" id="newsletterForm" className="gform" method="POST" onSubmit={(e)=>e.preventDefault()}>
                    <input type="email" placeholder="Enter Email" className="content" name="Email" required/>
                    <button type="submit" className="serif content">Send</button>
                    <div className="alertdemo"></div>
                </form>
                </div>

                
            </div>
        </section>
            <div className="copyright center column">
         <span>© Pulao Roast {year} </span>
         <span>Powered by <a href="https://www.cascadingninja.com" target="_blank" rel="noopener noreferrer">Cascading Ninja</a> </span>
         <span>All rights reserved.</span>
            </div>
            </section>
    )
}

export default Footer

import React from 'react'
import './Gallery.css'
import image1 from '../../images/backgroundmobile.jpg'
import image2 from '../../images/backgroundmobile.jpg'
import image3 from '../../images/DSC_0107.jpg'
import image4 from '../../images/DSC_9512.jpg'
import image5 from '../../images/DSC_9552.jpg'
import image6 from '../../images/menu/1.jpg'
import image7 from '../../images/menu/2.jpg'
import image8 from '../../images/menu/3.jpg'
import image9 from '../../images/menu/4.jpg'
import image10 from '../../images/menu/5.jpg'
import image11 from '../../images/menu/6.jpg'
import image12 from '../../images/menu/7.jpg'
import Brush from '../Brush/Brush'

function Gallery() {
    window.scrollTo(0,0)
    return (
        <>
         <section className="AlternateWrapper GalleryWrapper">
          <div className="AlternateDetails GalleryDetails">
              <div className="dots">........</div>
              <div className="AlternateContent  serif">
              A picture says a thousand words
              </div>
              <div className="AlternateHeading"> 
              {/* Welcome to Pulao Roast */}
             Our Gallery
              </div>
              
          </div>
        </section>
        <div className="aboutBrush"></div>
      
        <section className="Gallery">
            <div className="none">
            <div id="rightClick"></div>
        <div id="menuBrush"></div>
      </div>
            <div className="Galleryrow"> 
    <div className="imageColumn">
        <div className="imageWrapper">
        <img src={image1} alt="image 1" />
        </div>
        <div className="imageWrapper">
        <img src={image2} alt="image 2" />
        </div>
        <div className="imageWrapper">
        <img src={image3} alt="image 3" />
        </div>

    </div>
    <div className="imageColumn">
        <div className="imageWrapper">
        <img src={image4} alt="image 4" />
        </div>
        <div className="imageWrapper">
        <img src={image5} alt="image 5" />
        </div>
        <div className="imageWrapper">
        <img src={image6} alt="image 1" />
        </div>
        
    </div>  
    <div className="imageColumn">
        <div className="imageWrapper">
        <img src={image7} alt="image 4" />
        </div>
        <div className="imageWrapper">
        <img src={image8} alt="image 5" />
        </div>
        <div className="imageWrapper">
        <img src={image9} alt="image 1" />
        </div>
        
    </div> 
    <div className="imageColumn">
        <div className="imageWrapper">
        <img src={image10} alt="image 1" />
        </div>
        <div className="imageWrapper">
        <img src={image11} alt="image 2" />
        </div>
        <div className="imageWrapper">
        <img src={image12} alt="image 3" />
        </div>

    </div>
  
    
    </div>
        </section>
        </>
    )
}

export default Gallery

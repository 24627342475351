import React from 'react'
import Title from '../Title/Title'
import './SignatureDishes.css'
import background2 from '../../images/DSC_9552.jpg'
import background from '../../images/DSC_0107.jpg'
import background3 from '../../images/DSC_9512.jpg'
import {BsArrowRight} from 'react-icons/bs'
import Brush from '../Brush/Brush'
function SignatureDishes() {
    return (
        <>
        <section className="signatureDishes center column">
        <Title smallText="why People choose us" bigText="Our Signature Dishes"/>
        <div className="signatureCardWrapper ">
        <div className="box-item col-lg-4 col-md-5 col-xs-10">
            
        <div className="flip-box">
        <div className="flip-box-front text-center" 
        style={{background:`url(${background})rgba(17, 17, 17, 0.466)`}}
        > <div className="borderBoxTop Box"></div>
        <div className="borderBoxBottom Box"></div>
        <div className="inner color-white">
        <h3 className="flip-box-header serif">Chicken Jali Kebab</h3>
        {/* <p className="serif cardPara"><span style={{color:"red"}}>Original</span> complicated marinated net textured deep fried flavourful roasted chicken.</p> */}
        
        
    </div>
    <div className="CardBottom">01.</div>
    </div>
    <div className="flip-box-back text-center" 
    style={{background:`#111`}}
    >
    <div className="inner color-white">
        {/* <h3 className="flip-box-header serif">Chicken Jali Kebab</h3> */}
        <p className="serif cardPara"><span style={{color:"red"}}>Original</span> complicated marinated net textured deep fried flavourful roasted chicken.</p>
        
        {/* <button className="flip-box-button content">Learn More</button> */}
    </div>
    </div>
</div>
</div>
<div className="box-item col-lg-4 col-md-5 col-xs-10">
<div className="flip-box">
    <div className="flip-box-front text-center" 
    style={{background:`url(${background3})rgba(17, 17, 17, 0.466)`}}
    >
        <div className="borderBoxTop Box"></div>
        <div className="borderBoxBottom Box"></div>
    <div className="inner color-white">
        <h3 className="flip-box-header serif">Chicken Pulao</h3>
        {/* <p className="serif cardPara"><span style={{color:"red"}}>Original</span> pressure cooked marinated aromatic pulao rice layered with ghee serve with juicy, tender roasted chicken.</p> */}
        
        
    </div>
    <div className="CardBottom">02.</div>
    </div>
    <div className="flip-box-back text-center" 
    style={{background:`#111`}}
    >
    <div className="inner color-white">
        {/* <h3 className="flip-box-header serif">Chicken Pulao</h3> */}
        <p className="serif cardPara"><span style={{color:"red"}}>Original</span> pressure cooked marinated aromatic pulao rice layered with ghee serve with juicy, tender roasted chicken.</p>
    
        {/* <button className="flip-box-button content">Learn More</button> */}
    </div>
    </div>
</div>
</div>
<div className="box-item col-lg-4 col-md-5 col-xs-10">
<div className="flip-box">
    <div className="flip-box-front text-center filter-" 
    style={{background:`url(${background2})rgba(17, 17, 17, 0.466)`}}
    >
            <div className="borderBoxBottom Box"></div>
            <div className="borderBoxBottom Box"></div>
    <div className="inner color-white">
        <h3 className="flip-box-header serif">Mutton Pulao</h3>
        {/* <p className="serif cardPara"><span style={{color:"red"}}>Original</span> pressure cooked marinated tender roasted mutton served with aromatic pulao rice.</p> */}
        
        
    </div>
    <div className="CardBottom">03.</div>
    </div>
    <div className="flip-box-back text-center" 
    style={{background:`#111`}}
    >
    <div className="inner color-white">
        {/* <h3 className="flip-box-header serif">Mutton Pulao</h3> */}
        <p className="serif cardPara"><span style={{color:"red"}}>Original</span> pressure cooked marinated tender roasted mutton served with aromatic pulao rice.</p>
        
        {/* <button className="flip-box-button content">Learn More</button> */}
    </div>
    </div>
</div>
</div>
        </div>
        {/* <div className="landingBtn signatureBtn content"><span>Check out our full menu </span><BsArrowRight/> </div> */}
    
        </section>
       
        </>
    )
}

export default SignatureDishes

import React,{useEffect,useState} from 'react'
import {AiFillCaretLeft , AiFillCaretRight} from 'react-icons/ai'
import {Link} from 'react-router-dom'
import {BsArrowRight} from 'react-icons/bs'
import Image1 from '../../images/carousel/1.jpg'
import Image2 from '../../images/carousel/2.jpg'
import Image3 from '../../images/carousel/3.jpg'
import Image4 from '../../images/carousel/4.jpg'
function LandingSwipper() {
    const [counter, setCounter] = useState(0);
    function openFrame()
    {
      document.getElementById('promoVideo').style.display="flex";
      document.getElementById('frameYt').src="https://www.youtube.com/embed/6SBNejEtpXI?autoplay=1 ";
    }
    function closeFrame()
    {
      document.getElementById('promoVideo').style.display="none";
      document.getElementById('frameYt').src="  ";
    }
  function clickBtn()
  {
      document.getElementById('rightClick').click()
  }
useEffect(() => {
    setTimeout(() => {
        document.getElementById('rightClick').click()
        setCounter(counter+1)
    }, 6000);
    
}, [counter]);
function checkDevice()
{
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
    // true for mobile device
   
    document.getElementById('landingWrapper').style.backgroundAttachment="scroll"
    document.getElementById('landingSlider').style.display="none"

  }
}
// window.onload= checkDevice();
    return (
        <>
            
  
            <div id="landingSlider" className="carousel slide  carousel-fade landingSlider_indicators landingSlider_control_button thumb_scroll_x swipe_x" data-ride="carousel">
             
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                        <div className="landing_background">
                            <img src={Image1} className="img-circle img-responsive" />
                            <div className="overlay"></div>
                            
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="landing_background">
                            <img src={Image2} className="img-circle img-responsive" />
                            <div className="overlay"></div>
                            
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="landing_background">
                            <img src={Image3} className="img-circle img-responsive" />
                            <div className="overlay"></div>              
                            
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className="landing_background">
                            <img src={Image4} className="img-circle img-responsive" />
                            <div className="overlay"></div>              
                            
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev " href="#landingSlider" data-slide="prev">
                    <span className="carousel-control-prev-icon carousel-control"><AiFillCaretLeft color="#C19D60"/></span>
                </a>
                <a className="carousel-control-next " href="#landingSlider" data-slide="next" id="rightClick">
                    <span className="carousel-control-next-icon carousel-control"><AiFillCaretRight color="#C19D60"/></span>
                </a>
            </div>
      <div className="promoVideo" id="promoVideo">
        <div className="closeVideo" onClick={closeFrame}>&times;</div>
        <iframe  src="" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" autoPlay allowFullScreen id="frameYt"></iframe>
        </div>

        <section className="landingWrapper h-fscreen" id="landingWrapper">
          <div className="landingDetails">
                            <div className="dots">........</div>
              <div className="landingContent serif">
              Top Services and Premium Cuisine
              </div>
              <div className="landingHeading" style={{textTransform:"uppercase"}}> 
              {/* Welcome to Pulao Roast */}
              Taste that you crave
              </div>
              <a href="https://orders.pulaoroast.com/" target="_blank" rel="noreferrer noopener" className="landingBtn content"><span>Order Now </span><BsArrowRight/> </a>
          </div>
          <div className="buttonContainer content" id="playBtn" onClick={()=>openFrame()}>
                    <span className="button is-play" href="#">
                    <div className="button-outer-circle has-scale-animation"></div>
                    <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                    <div className="button-icon is-play">
                      <svg height="100%" width="100%" fill="#C19D60">
                        <polygon className="triangle" points="5,0 30,15 5,30" viewBox="0 0 30 15"></polygon>
                        <path className="path" d="M5,0 L30,15 L5,30z" fill="none" stroke="#C19D60" strokeWidth="1"></path>
                      </svg>
                    </div></span></div>
        </section>
        </>
    )
}

export default LandingSwipper

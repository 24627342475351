import React from 'react'

function OpeningHourComponent() {
    return (
        <>
           <div className="hourWrapper center column">
                        <div className="center column">
                            <span className="serif">We're at your service</span>
                            <h3 className="serif">Opening Hours</h3>
                            <span className="dots">.......</span>
                        </div>
                        <div className="timingWrapper ">
                            <div className="timingDiv column center col-md-6">
                                <div className="timingDay">Sunday to Tuesday</div>
                                <div className="timing">11:00</div>
                                <div className="timing">23:00</div>
                            </div>
                            <div className="timingDiv column center col-md-6">
                                <div className="timingDay">Wednesday to Saturday </div>
                                <div className="timing">11:00</div>
                                <div className="timing">23:00</div>
                            </div>
                        </div>
                        <a className="reservationBtn serif content">
                        +91 70038 85406
                        </a>
                    
                    </div> 
        </>
    )
}

export default OpeningHourComponent

import React from 'react'
import './Contact.css'
import OpeningHourComponent from '../OpeningHours/OpeningHourComponent'
import Title from '../Title/Title'
import {BsArrowRight} from 'react-icons/bs'
import Brush from '../Brush/Brush'
function Contact() {
    return (

        <section className="Contact h-fscreen column">
            <div className="none">             
              <div id="menuBrush"></div>
              <div id="rightClick"></div>
            </div>

             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.9417501307657!2d88.48628745027966!3d22.581281838270503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a020b8d44cde0a3%3A0x7d5a5c873b00053e!2sPulao%20Roast!5e0!3m2!1sen!2sin!4v1660057389132!5m2!1sen!2sin" id="contactPageMap"></iframe>
             <div className="aboutBrush"></div>
            <div className="row contactWrapper ">
            <div className="topBorder"></div> 
            <div className="formWrapper col-md-6 col-sm-12">
            <Title bigText="Get in touch"/>
                <form action="https://script.google.com/macros/s/AKfycbydnPjiG0tjtHg1OKby3Q42ov2NzjZHm4E5IBaEupTYdj2zTnm8LdR1C4AXuVIOi5iX/exec" id="contactForm" className="column gform" method="POST" onSubmit={(e)=>e.preventDefault()}>
                   <p className="contactPara">Reach out to us 
                   <div className="contactDetail">
                       <a className=" content contactIcon"><i className="fa fa-phone" aria-hidden="true"></i> +91 70038 85406</a>
                       <a className=" content contactIcon"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> hello@pulaoroast.com</a>
                       <a className=" content contactIcon"><i className="fa fa-map-marker" aria-hidden="true"></i> TCS Gitanjali Park, Newtown</a>
                   </div>
                   </p>
                   <div className="form-row">
                       <div className="col-md-5 col-sm-10">
                           <input type="text" placeholder="Your name*"  name="Name" className="reservationInput contactInput" required />
                       </div>
                       <div className="col-md-5 col-sm-10">
                           <input type="email" placeholder="Your email*" name="Email" className="reservationInput contactInput" required/>
                       </div>
                   </div>
                   <div className="form-row">
                       <div className="col-md-5 col-sm-10">
                           <input type="tel" placeholder="Phone*" name="Phone" className="reservationInput contactInput" required/>
                       </div>
                       <div className="col-md-5 col-sm-10">
                           <input type="text" placeholder="Subject" name="Subject" className="reservationInput contactInput" required/>
                       </div>
                   </div>
                   <div className="form-row">
                       <div className="col-sm-10">
                           <textarea type="tel" placeholder="Your Message:" name="Message" className="reservationInput contactInput textareaInput" required/>
                       </div>                       
                   </div>
                   <div className="form-row">
                       <div className="col-md-5 col-sm-10">
                           <button type="submit" className="storeBtn contactSubmitBtn center">Send Message &nbsp;<BsArrowRight/></button>
                       </div>                       
                   </div>
                   <div className="form-row thankyou_message">
                       <h3 className="serif col-md-12" id="demo" style={{fontStyle:"italic"}}> </h3>
                   </div>
                </form>
            </div>
            <div className="col-md-5 col-sm-12 openingWrapper hourDiv center">
                <OpeningHourComponent/>
            </div>
            </div>
        </section>
    )
}

export default Contact

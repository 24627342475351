import React from 'react'
import './OpeningHours.css'
import Signature from '../../images/signature.png'
import OpeningHourComponent from './OpeningHourComponent'
function OpeningHours() {
    return (
        <section className="openingHours">
            <div className="row">
                <div className="col-lg-6 col-md-12 openingWrapper quoteDiv center">
                    <div className="quoteWrapper column center">
                        <div className="center column ">
                      <i className="fa fa-quote-right" aria-hidden="true"  id="quoteIcon"></i>
                      <div className="quote">
                          "I was always passionate about cooking since childhood but For me it’s my mother who is the best cook she was a rural suburb and we didn’t have electricity back in date, but from that enormous mud stove got out the most incredible foods and it was soul feeling. From there I learnt one thing, and that’s the fresh organic ingredients and an art of passion which created those delicious dishes. Myself being a certified nutritionist, it’s not just taste which matters but also our human metabolism and taking care of both is my preference, when combined both make a truly soul-filling dish. You don’t have to cook complicated masterpieces, just good food from fresh ingredients. Also, there is not a good or a bad cuisine, just the one you like the best."
                      </div>
                      </div>
                      <div className="designation">
                        <span>Suchitra Biswas - Director & Head chef
                        </span>
                        <img src={Signature} alt="" />
                      </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 openingWrapper hourDiv center ">
                   <OpeningHourComponent/>
                </div>
            </div>
        </section>
    )
}

export default OpeningHours

import React from 'react'
import { Link } from 'react-router-dom'
import './Menu.css'
import Title from '../Title/Title'
import Brush from '../Brush/Brush'
import Menu from './Menu'
import {BsArrowRight} from 'react-icons/bs'
import MenuSpecials from './MenuSpecials'
function MenuSmall() {
    return (
        <>
        <div className="menuBrush" id="menuBrush"></div>
        <section className="menuSmall column h-fscreen center">
            
            <section className="title column center" style={{padding: "0 2.5vw"}}>
            <h5 className="serif smallText ">Special Menu Offers</h5>
            <h3 className="serif bigText " style={{color:"#fff"}}>Enjoy Restaurants Specialties</h3>
            <div className="dots">.......</div>
        </section>
            <MenuSpecials count={6}/>
            <Link to="/menu" className="storeBtn content ">Open Full menu &nbsp; <BsArrowRight/></Link>
        </section>
        {/* <div className="menuBrushBottom blackBrush"></div> */}
        </>
    )
}

export default MenuSmall
